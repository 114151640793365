@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");


.fade-div {
  animation: fadeIn 300ms;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.fs-xsm {
  font-size: 13px !important;
}

.bb-1-yellow {
  border-bottom: 1px solid #FFDC00;
}

.w-50-responsive {
  width: 50%;
}

.c-pointer {
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.c-pointer:hover {
  background-color: rgba(159, 159, 159, 0.153);
  border-radius: 5px;
  transform: translateY(-10px);
}

.card-pay {
  padding: 20px;
  border-radius: 20px;
  background-image:
    linear-gradient(rgba(255, 195, 84, 0.518), rgba(255, 136, 0, 0.433)),
    /* Orange overlay with 50% opacity */
    url("https://img.freepik.com/premium-photo/cloud-security-concept-with-padlocks-cloud-icons_1110022-43434.jpg");
  background-size: cover;
  /* Blend the gradient and image */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card-pay:hover {
  cursor: pointer;
  box-shadow: 0px 41px 37px -3px rgba(0, 0, 0, 0.1);
  transform: translateY(-10px);
  /* Mover hacia arriba al pasar el cursor */
}

.icon-pay {
  width: 50px;
  height: 50px;
}


@media screen and (max-width: 1000px) {
  .bg-healt-pro {
    width: 90% !important;
  }

  .w-50-responsive {
    width: 100% !important;
  }
}

.justify-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pro-filter {
  /* From https://css.glass */
  background: rgba(208, 99, 4, 0.344);
  border-radius: 16px;
  backdrop-filter: blur(5.7px);
  -webkit-backdrop-filter: blur(5.7px);
}

.bg-healt-pro {
  background-image: url("https://firebasestorage.googleapis.com/v0/b/dslb-wsb-cdn-americas.appspot.com/o/myqralert%2Funion-health-banner-website-blank.jpg?alt=media&token=58c54146-9d9d-4eb9-9d37-c3e71a7552c4");
  background-position: center;
  background-size: contain;
  width: 70%;
}

.container-cardsubs {
  color: #fff;
  border-radius: 10px;
  padding: 10px;
  width: auto !important;
}

.bannerPro {
  width: 200px;
  border-radius: 10px;
  color: #69462f;
  text-align: center;
  background-position: center;
  background-size: cover;
  background-image: url('https://files.123freevectors.com/wp-content/original/124090-abstract-shiny-gold-metal-texture-background.jpg');
}

#root {
  padding: 0 !important;
  margin: 0 !important;
}

.container {
  min-width: 100% !important;
}

* {
  margin: 0% !important;
  font-family: "Poppins", sans-serif !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

a {
  color: #000;
  text-decoration: none;
}

a:hover {
  color: rgb(78, 78, 78);
}

h1 {
  color: #0D0D0D;
}

.form-check-input:checked {
  background-color: #F2CB05;
  border-color: #F2CB05;
}

.iconContainer {
  background: url(./assets/icons/LOGO-PNG.png);
  width: 300px;
  background-size: cover;
  height: 300px;
}

.containerNameMb {
  width: 70%;
}

.iconContainerSmall {
  background: url(./assets/icons/LOGO-PNG.png);
  width: 100px;
  background-size: cover;
  height: 100px;
}

.legalsLink {
  font-size: 15px;
  color: #ffffff;
}

#linktologin {
  text-decoration: inherit;
  color: inherit;
}

/* 
.accordion-button:not(.collapsed)::after{
  url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230c63e4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e);
} */


.cuadroTabla {
  border: 1px solid #000;
  border-radius: 20px;
  padding: 10px;

  -webkit-box-shadow: 0px 10px 18px -7px rgba(189, 189, 189, 0.62);
  -moz-box-shadow: 0px 10px 18px -7px rgba(189, 189, 189, 0.62);
  box-shadow: 0px 10px 18px -7px rgba(189, 189, 189, 0.62);
}

.inputBuscarPedido {
  width: 500px !important;
  border-radius: 20px;
  border: 0.8px solid #000;
  margin: 30px !important;
}

.contenedorFormulario {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
}

.formularioPedido {
  padding: 10px;
  width: 700px;
  height: auto;
  border: 1px solid rgb(231, 231, 231);
  border-radius: 10px;
}

/** assets **/

.shopI {
  width: 200px;
}

.iconNewOrder {
  width: 200px;
}

/**interfaces*/
.linkView {
  color: #000;
  text-decoration: underline;
}

.linkView:hover {
  color: #000;
  text-decoration: underline;
}

.btn-comprar {
  background-color: #3cc0b0;
  border: 0px;
}

.btn-comprar:hover {
  background-color: #fff;
  color: #3cc0b0;
  border: 1px solid #3cc0b0;
}

.row-product {

  -webkit-box-shadow: 10px 10px 61px 0px rgba(0, 0, 0, 0.11);
  -moz-box-shadow: 10px 10px 61px 0px rgba(0, 0, 0, 0.11);
  box-shadow: 10px 10px 61px 0px rgba(0, 0, 0, 0.11);
}


/* App meta invest */


/* Botones */
.btn-main-rose {
  border-radius: 50px;
  background-color: #F4CFC6;
  color: #000;
}


.btn-main-black:hover {
  -webkit-box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.27);
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.27);
  color: #fff;
}


.btn-main-white {
  border-radius: 0%;
  background-color: #fff;
  color: #000;
  border: 1px solid #000;
}

.btn-main-white:hover {
  -webkit-box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.27);
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.27);
  color: #000;
}


.txt-ganancia {
  color: #7FDF5D;
  font-size: 60px;
}

.profile-indicator {
  border-radius: 50px;
  width: 300px;
  height: 35px;
  align-items: center;
  background-color: #F4F4F4;
}

.custom-badge-black {
  background-color: #000;
  color: #fff;
}

/* Textos */

.data-client-profile {
  font-size: 13px;
  color: rgb(99, 99, 99);
}

.background-successfully-operation {
  min-height: 100vh;
  background-image: url('/src/assets/001_detalles_background.png');
  background-repeat: no-repeat;
  background-size: cover;
}

.glass-message-confirmation {
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);

  height: 200px;
  position: relative;
  top: 200px;
  padding: 20px;
}

.text-white {
  color: #fff;
}

.title-white {
  color: #fff;
}

.container-group {
  flex-direction: column;
  justify-content: center !important;
  align-items: center;
}

.h-50 {
  height: 50% !important;
}

.container-inversion-return {
  border: 1px solid #D4D4D4;
  border-radius: 20px;
  width: 300px;
  display: flex;
  justify-content: center;
}

.background_login {
  min-height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(assets/005_BACGROUND_LOGIN.png);
}

.r-20 {
  border-radius: 20px;
}

.h-300 {
  height: 300px !important;
}

th {
  width: 200px !important;
}

.background_reset {
  min-height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(assets/011_background_reset_password.jpg);
}



.login-section {
  position: relative;
  width: 500px;
  top: 100px;
  left: 90px;
}

.login-card {
  background-color: #fff;
  border-radius: 20px;
  width: 360px;
  -webkit-box-shadow: 0px 10px 35px -18px rgba(0, 0, 0, 0.59);
  -moz-box-shadow: 0px 10px 35px -18px rgba(0, 0, 0, 0.59);
  box-shadow: 0px 10px 35px -18px rgba(0, 0, 0, 0.59);
}

.signup-card {
  background-color: #fff;
  border-radius: 20px;
  width: 350px;
  -webkit-box-shadow: 0px 10px 35px -18px rgba(0, 0, 0, 0.59);
  -moz-box-shadow: 0px 10px 35px -18px rgba(0, 0, 0, 0.59);
  box-shadow: 0px 10px 35px -18px rgba(0, 0, 0, 0.59);
}

.glass-welcome-container {
  /* From https://css.glass */
  background: rgba(22, 22, 22, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(22, 22, 22, 0.05);


  height: 200px;
  position: relative;
  top: 200px;
  padding: 20px;

}

.background-signup {
  min-height: 100vh;
  background-image: url(assets/006_background_login.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0px -200px;
}

.background-admin {
  min-height: 100vh;
  background-image: url(assets/008_background-admin.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0px -200px;
}

.background-addtags {
  min-height: 100vh;
  background-image: url(assets/009_background_addtask.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0px -200px;
}

.background-new-tag {
  min-height: 100vh;
  background-image: url(assets/007_background_new_tag.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0px -200px;
}


.file-uploader-input {
  border: 1px solid #dbdbdb;
  border-radius: 5px;
  height: 50px;
  display: flex;
  align-content: center;
  align-items: center;
  padding: 10px;
  border-style: dashed;
}


/*table*/

.shadow-table {

  padding: 15px;
  height: 400px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: linear-gradient(229.04deg, #FFFFFF 6.06%, #F1F1F1 100%);
  -webkit-box-shadow: 0px 10px 35px -18px rgba(0, 0, 0, 0.59);
  -moz-box-shadow: 0px 10px 35px -18px rgba(0, 0, 0, 0.59);
  box-shadow: 0px 10px 35px -18px rgba(0, 0, 0, 0.59);
  border-radius: 30px;
}

.button-action-card-1 {
  border-radius: 20px;
  width: 200px;
  height: 200px;
  margin: 20px !important;
  background: #F26716;
  box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
}

.button-action-card-2 {
  border-radius: 20px;
  width: 200px;
  height: 200px;
  margin: 20px !important;
  background: linear-gradient(180deg, #FF8A00 0%, #FFB800 100%);
  box-shadow: 0px 20px 20px rgba(255, 138, 0, 0.25);

}

.button-action-card-3 {
  border-radius: 20px;
  width: 200px;
  height: 200px;
  margin: 20px !important;
  background: linear-gradient(180deg, #AB1D48 0%, #E90046 100%);
  box-shadow: 0px 20px 20px rgba(171, 29, 72, 0.25);
}

.button-action-card-4 {
  border-radius: 20px;
  width: 200px;
  height: 200px;
  margin: 20px !important;
  background: linear-gradient(180deg, #F3F3F3 0%, #FFFFFF 100%);
  box-shadow: 0px 20px 20px rgba(141, 141, 141, 0.25);
}

.iconActionCard {
  width: 180px;
  position: relative;
  left: -14px;
  top: -10px;
}


.title-card-action {
  color: #fff;
  font-size: 20px;
  font-weight: 500;
}

/* Icons */

.icon-container-card-1 {
  height: 150px;
  background-position: center;
  background-size: 150px;
  background-repeat: no-repeat;
  background-image: url(assets/icons/iconMyTagsWithText.svg);
}

.icon-container-card-2 {
  height: 150px;
  background-position: center;
  background-size: 150px;
  background-repeat: no-repeat;
  background-image: url(assets/icons/009_user_icon.png);
}

.icon-container-card-3 {
  height: 150px;
  background-position: center;
  background-size: 150px;
  background-repeat: no-repeat;
  background-image: url(assets/icons/010_qr_icon.png);
}

.icon-container-card-4 {
  height: 150px;
  background-position: center;
  background-size: 150px;
  background-repeat: no-repeat;
  background-image: url(assets/icons/NewTagBlack.svg);
}

.iconNoNotificacions {
  height: 150px;
  background-position: center;
  background-size: 150px;
  background-repeat: no-repeat;
  background-image: url(assets/icons/NoNotificationsIcon.svg);
}

.iconNoActiveTags {
  height: 300px;
  background-position: center;
  background-size: 300px;
  background-repeat: no-repeat;
  background-image: url(assets/icons/014_emptyIcon.svg);
}

.iconSuccess {
  height: 300px;
  background-position: center;
  background-size: 300px;
  background-repeat: no-repeat;
  background-image: url(assets/icons/015_success_icon.svg);
}

/* Pet */

.pet-circle-image {
  border-radius: 50%;
  width: 200px;
  height: 200px;
  background-position: center;
  align-self: center;
  background-size: cover;

}

.pet-circle-image-details {
  border-radius: 50%;
  width: 120px;
  height: 120px;
  background-position: center;
  align-self: center;
  background-size: cover;
}

.pet-circle-name {
  color: #000;
  font-weight: 600;
  font-size: 20px;
  align-self: center;
  margin-bottom: 20px !important;
}


/* Tags */


.badge-info {
  width: 400px;
  background: linear-gradient(269.63deg, #FFE600 0.22%, #FF8A00 99.68%);
  border-radius: 20px;
  text-align: center;
  margin: 20px !important;

}

.badge-info-blue {
  background: linear-gradient(90.1deg, #0066FF 0.06%, #00FFF0 99.91%);
  border: 1px solid #EBEBEB;
  height: 80px;
  align-content: center !important;

  box-sizing: border-box;
  box-shadow: 0px 10px 10px rgba(139, 141, 141, 0.25);
  text-align: center;
  border-radius: 20px;
  align-self: center;
  margin: 20px !important;
}

.input-file {
  width: 300px;
  align-self: center;
  margin: 20px !important;
}

.badge-title {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 75px;
  /* identical to box height */
  color: #FFFFFF;
}


.shadow {
  border-radius: 20px;
  -webkit-box-shadow: 0px 10px 96px -46px rgba(77, 77, 77, 0.16);
  -moz-box-shadow: 0px 10px 96px -46px rgba(60, 60, 60, 0.16);
  box-shadow: 0px 10px 96px -46px rgba(57, 57, 57, 0.16);
}

.info-text-container {
  border: 1px solid #C6C6C6;
  border-radius: 20px;
  padding: 20px;
  width: 400px;
}

.reward-text-container {
  border: 1px solid #32FF53;
  background-color: #32FF53;
  border-radius: 20px;
  padding: 20px;
  width: 400px;

}

.button-card-info-pet {
  color: #fff;
  width: 200px;
  font-size: 20px;
  margin: 10px !important;
  border: 0px !important;
  background: linear-gradient(269.63deg, #FFE600 0.22%, #FF8A00 99.68%);
  border-radius: 20px;
}

.rotateImage {
  position: relative;
  width: 100px;
  top: -200px;
  transform: rotate(90deg);
}

.btn-not-found {
  position: relative;
  top: -400px;
}

.btnAddIndividualTag {
  background-color: #e98400;
  margin: 20px !important;
}




/* Validators */

.invalidNumber {
  align-self: center;
  background-color: #ffc8c8;
  color: #8a0000;
  border: 1px solid #fa7575;
}

.correctNumber {
  align-self: center;
  color: #0567a0;
  background-color: #c7e6ff;
  border: 1px solid #7ecbf8;
}

.correctNumber[type="text"],
textarea {
  align-self: center;
  background-color: #b3ddff;
}

/* terminos */

.bodybackground {
  background-color: #F7F7F7;
}

.parraf {

  margin-top: 2% !important;
  margin-bottom: 2% !important;
  color: #000;
  font-size: 18px;
  text-align: justify !important;
}

.main-container {
  width: 100%;
  padding: 5%;
}

.text-center {
  color: #000;
}

/* Responsive */



#row-mb {
  display: none;
}

@media screen and (max-width: 1000px) {
  #row-pc {
    display: none !important;
  }

  #row-mb {
    display: block;
  }

  .badge-info {
    width: 300px;
  }

  .badge-info-blue {
    width: 300px;
  }
}


/* Myqralert */

#containerLogin {
  align-items: center;
  min-height: 100vh;
  justify-content: center;
  background: #F2F2F2;
}

.btnBlack {
  background: #F26716;
  color: #fff;
  font-weight: 700;
  border-radius: 10px;
  font-size: 20px;
  width: 280px;
}

.btnBlackHoverWhite {
  background: #F2CB05;
  color: #fff;
  font-weight: 700;
  border-radius: 10px;
  font-size: 20px;
  width: 280px;
}

.btnBlack:hover {
  background: #fa8e4f;
  color: #fff;
}

.btnBlackHoverWhite:hover {
  background: #fff;
  color: #000;
}

.m-form-control {
  background: #fbfbfb;
  height: auto;
  border: 0.5px solid #c1c1c1;

}


/* Text */

.firstText {
  color: #F26716;
  font-weight: 600;
}

.myQRAlertBlack {
  font-size: 40px;
  font-weight: 600;
  color: #fff;
}

.myQRAlert {
  color: #FFE600 !important;
  font-size: 40px;
  font-weight: 600;
}

.container-myQrCard {
  border-radius: 10px;
  background-color: #000;
  width: 330px;
  text-align: center;
}

.containerDashboard {
  background: #F2F2F2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.separator {
  height: 20px;
}

.columTitle {
  margin: 20px !important;
  font-size: 30px;
  font-weight: bolder;
  color: #F26716;
}

.userNameText {
  margin: 20px !important;
  font-size: 30px;
  font-weight: bolder;
  color: #F26716;
  position: absolute;
  left: 50px;
  top: 60px;
}

.myQRAlertText {
  margin: 20px !important;
  font-size: 30px;
  font-weight: bolder;
  color: #000;
  position: absolute;
  left: 50px;
  top: -10px;
}

.primerColumna {
  background: #fff;

  justify-content: center;
  position: relative;
  align-items: center;
  left: 50px;

  height: auto;
  display: flex;
  top: 20px;
  background: linear-gradient(229.04deg, #FFFFFF 6.06%, #F1F1F1 100%);
  -webkit-box-shadow: 0px 10px 35px -18px rgba(0, 0, 0, 0.59);
  -moz-box-shadow: 0px 10px 35px -18px rgba(0, 0, 0, 0.59);
  box-shadow: 0px 10px 35px -18px rgba(0, 0, 0, 0.59);
  border-radius: 30px;
}

.containerFirmaDSLB>p {
  color: #000;
}

.btnMyProfile {
  background: #F2CB05;
  color: #fff;
  border-radius: 7px;
  width: 180px;
  font-size: 13px;
  font-weight: 600;
}

.btnMyProfile:hover {
  background: #ecd14a;
  color: #fff;
  border-radius: 5px;
}

.btnLogout {
  background: #fff;
  color: #000;
  border-radius: 7px;
  width: 150px;
  font-size: 18px;
  font-weight: 600;
}

.btnLogout:hover {
  background: #ED4747;
  color: #fff;
  border-radius: 5px;
}

.btnLogoutActive {
  background: #ED4747;
  color: #fff;
  border-radius: 7px;
  width: 150px;
  font-size: 18px;
  font-weight: 600;
}

.btnLogoutActive:hover {
  background: #a01c1c;
  color: #fff;
  border-radius: 5px;
}

.containerProfileLogout {
  display: flex;
  flex-direction: row;
  position: absolute;
  right: 120px;
  top: 60px;
  justify-content: space-between;
  width: 320px;
}

.secondText {
  font-size: 35px;
  font-weight: 600;
}

.goBackButton {
  position: absolute;
  left: 125px;
  top: 140px;
}

label {
  color: #F26716;
  font-weight: 600;
  font-size: 17px;
}

.tagContainer {
  width: 300px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  background: #fff;
  flex-direction: row;


  -webkit-box-shadow: 0px 10px 35px -18px rgba(0, 0, 0, 0.59);
  -moz-box-shadow: 0px 10px 35px -18px rgba(0, 0, 0, 0.59);
  box-shadow: 0px 10px 35px -18px rgba(0, 0, 0, 0.59);
}

.tagContainer:hover {
  background: #F2F2F2;
}

.tagPersonName {
  color: #000;
  font-weight: 600;
  font-size: 20px;
  cursor: pointer;
}

.tagImage {
  border-radius: 50px;
  width: 90px;
  height: 90px;
  background-position: center;
  margin: 10px !important;
  outline: 3px solid #F26716;
}

.fieldRequired {
  font-size: 16px;
  color: #FF0F2F;
}

/* Containers */
.containerAlert {
  text-align: center;
}


.containerButtonAcordion {
  border-radius: 10px !important;
}

.buttonCardAcordion {
  border-radius: 10px !important;
  width: 100%;

  font-size: 30px;
  font-weight: 600;

  -webkit-box-shadow: 0px 10px 35px -18px rgba(0, 0, 0, 0.59);
  -moz-box-shadow: 0px 10px 35px -18px rgba(0, 0, 0, 0.59);
  box-shadow: 0px 10px 35px -18px rgba(0, 0, 0, 0.59);
}


.titleText {
  color: #F26716;
  font-size: 17px;
  font-weight: 600;
}

.dataText {
  font-size: 15px;
}


/* Acordion */

.accordion-button:not(.collapsed) {
  color: #fff;
  background-color: #F26716;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}

/* Modals */
.modalCenter {
  top: 50% !important;
  left: 37% !important;
  transform: translateY(-50%) !important;
}

/* Dividers */

.divider {
  border-bottom: 2px solid rgb(207, 207, 207);
}

/* Alerts or warnings */
.parrafAlert {
  background: rgb(240, 240, 240);
  border-radius: 10px;
  padding: 2%;
  margin-top: 2% !important;
}

.containerPersonPhoto {
  background: #fff;
  position: relative;
  left: 220px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 2% !important;
  border-radius: 10px;
  padding: 2%;

  -webkit-box-shadow: 0px 10px 35px -18px rgba(0, 0, 0, 0.59);
  -moz-box-shadow: 0px 10px 35px -18px rgba(0, 0, 0, 0.59);
  box-shadow: 0px 10px 35px -18px rgba(0, 0, 0, 0.59);
}

.nameUserMb {
  margin-top: 50px !important;
  font-size: 30px;
  font-weight: 600;
}

.myQRAlertTextMb {
  font-size: 30px;
  position: relative;
  top: -30px !important;
  font-weight: 600;
}


/* *** REDESING HOMEPAGE 2022 ****/
.icon-emergency {
  background: url("https://firebasestorage.googleapis.com/v0/b/myqralert.appspot.com/o/card-imgs%2Femergency-3d-icon.png?alt=media&token=eb868f32-8687-4e90-885b-b99737fefd0e");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  max-width: 300px;
  height: 300px;
}

.icon-avatar {
  background: url("https://firebasestorage.googleapis.com/v0/b/myqralert.appspot.com/o/card-imgs%2Favatar-icon.png?alt=media&token=d813157c-15fb-4a6f-afd1-a62bd09d61b6");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  max-width: 300px;
  height: 300px;
}

.icon-registro-medico {
  background: url("https://firebasestorage.googleapis.com/v0/b/myqralert.appspot.com/o/card-imgs%2Fregistro-medico-icon.png?alt=media&token=7b1875c0-2d6e-40e7-a28d-82b46d6ee1c4");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  max-width: 300px;
  height: 300px;
}

.icon-correos {
  background: url("https://firebasestorage.googleapis.com/v0/b/myqralert.appspot.com/o/card-imgs%2Femail-icon.png?alt=media&token=8025bf9c-9cb1-42a5-a3a3-f0405166496a");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  max-width: 300px;
  height: 300px;
}

.icon-switch {
  background: url("https://firebasestorage.googleapis.com/v0/b/myqralert.appspot.com/o/card-imgs%2Fswitch-icon.png?alt=media&token=a67278da-f216-4476-9f0e-65c888ace068");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  max-width: 300px;
  height: 300px;
}

.icon-qr-3d {
  background: url("https://firebasestorage.googleapis.com/v0/b/myqralert.appspot.com/o/card-imgs%2Fqr-icon.png?alt=media&token=ccaf589f-f455-42f1-808e-b6c2eff4501b");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  max-width: 300px;
  height: 300px;
}

.icon-restart {
  background: url("https://firebasestorage.googleapis.com/v0/b/myqralert.appspot.com/o/card-imgs%2Fback-icon.png?alt=media&token=7ebf91d7-0ca8-4a0c-b4dd-0a96271e40d1");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  max-width: 300px;
  height: 300px;
}

.icon-coords {
  background: url("https://firebasestorage.googleapis.com/v0/b/myqralert.appspot.com/o/card-imgs%2Fcoords-icon.png?alt=media&token=3889877e-6b4a-4daa-8fcc-30a27ecac733");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  max-width: 300px;
  height: 300px;
}

.icon-chat {
  background: url("https://firebasestorage.googleapis.com/v0/b/myqralert.appspot.com/o/card-imgs%2Fchat-icon.png?alt=media&token=2d9f1aae-a20e-4413-8ea3-56c41d9674dc");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  max-width: 300px;
  height: 300px;
}

.icon-family {
  background: url("https://firebasestorage.googleapis.com/v0/b/myqralert.appspot.com/o/card-imgs%2Ffamilia-3d.png?alt=media&token=befc7048-2134-461c-872e-0f45d3fb7c00");
  background-size: contain;
  height: 300px;
  width: 300px;
  background-repeat: no-repeat;
  background-position: center;
  max-width: 300px;
  height: 300px;
}

/* *** ENDS REDESING HOMEPAGE 2022 ****/




/* Wabutton */


.wp-button {
  margin-top: 14px;
}

.float {
  position: fixed;

  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("https://firebasestorage.googleapis.com/v0/b/idpets.appspot.com/o/icons8-whatsapp%201.svg?alt=media&token=74a1a8ab-8794-4f4a-8a2f-ce7cb05b0731");
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #FFF;
  border-radius: 50px;
  padding: 0% !important;
  text-align: center;
  font-size: 35px;
  z-index: 100;
}

.float:hover {
  background-color: #009838 !important;
  border: 0px;
  color: #25d366 !important;
  border: 1px solid #25d366;
  transition: 0.3s;
}

/* Ends wa button */



/* Messages client */
.container-full-space {
  animation: fadeInAnimation ease 100ms;
  height: 100vh;
  width: 100vw;
  background-color: #fff;
  position: fixed;
  padding: 10px;
  top: 0;
  left: 0;
  z-index: 999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.response-admin {
  background-color: rgb(255, 227, 133) !important;
  border-radius: 10px !important;
  width: 500px;
}

.response-client {
  background-color: rgb(247, 247, 247) !important;
  border-radius: 10px !important;
}

/* End Messages client */



/* M modal */

.m-modal {
  position: absolute;
  z-index: 1;
  top: 0;
  height: 100%;

  width: 100%;


  background-color: rgba(0, 0, 0, 0.362);

  display: flex;
  justify-content: center;
  align-items: center;
}

.m-container {
  width: 60%;
  height: 80%;

  display: flex;
  padding: 1em;
  border-radius: 10px;
}

.m-modal-body {
  margin-left: auto !important;
  height: auto !important;
  margin-right: auto !important;
}

/* Ends M Modal */


/* Info page */

.icon-info-circle {
  align-self: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 110px;
  height: 110px;
  cursor: pointer;
}


/* CUSTOM */
.mty-5 {
  margin-top: 4em !important;
  margin-bottom: 1em !important;
}

.w-100 {
  width: 100% !important;
}

.c-pointer {
  cursor: pointer;
}


.fade-div {
  animation: fadeIn 300ms;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}


.mw-auto {
  width: auto !important;
}

.fs-12 {
  font-size: 12px;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-light {
  color: #FFF !important;
}

.text-orange {
  color: #F26716;
}

.bg-orange {
  background-color: #F26716 !important;
}

.bg-dark {
  background-color: #000 !important;
}

.my-6 {
  margin-top: 6em !important;
  margin-bottom: 6em !important;
}


.fs-12 {
  font-size: 13px;
}

.mh-100 {
  min-height: 100vh !important;
}

.bg-light-gray {
  background-color: rgb(242, 242, 242);
}

.bg-success {
  background-color: #9ed68a !important;
}

.bred {
  border: 1px solid red !important;
}

/* style for row */
.trRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px;
  border-bottom: 1px solid rgb(224, 224, 224);
}

/* ENDS CUSTOM */


/* RESPONSIVE */


@media only screen and (max-width: 1000px) {


  .response-admin {
    width: 300px !important;
  }

  .mw-50-responsive {
    width: 90% !important;
  }


  .containerProfileLogout {
    display: flex;
    flex-direction: row;
    position: relative;
    left: 7px;
    justify-content: right;
    top: 100px;
  }

  .btnMyProfile {
    width: 100px;
  }

  .btnLogout {
    width: 100px;
  }

  .shadow-table {
    width: auto !important;
    height: auto;
    align-self: center;
    position: relative;
    left: 0%;
    margin: 10px !important;
    margin-bottom: 20% !important;
  }

  #sectionActionsContainer {
    align-items: center;
  }

  .containerMb {
    min-height: 100vh;
  }

  .columTitle {
    font-size: 20px;
    text-align: center;
  }

  .userNameText {
    display: none;
  }

  .myQRAlertText {
    display: none
  }

  .firmaMb {
    font-size: 15px;
    text-align: center;
  }

  .containerFirmaDSLB {
    display: none;
  }

  .btnMyProfile {
    width: 200px;
  }

  .pet-circle-image-details {
    width: 120px !important;
    height: 120px !important;
  }

  .modalCenter {
    left: 0% !important;
    margin: 1% !important;
  }

  .inputBuscarPedido {
    width: auto !important;
  }
}

.containerButtonBackMb {
  margin-top: 10% !important;
}

.nameUserCard {
  font-size: 17px;
  font-weight: 600;
  align-self: center;
}

.containerNameUserNameCard {
  width: 50px;
  display: flex !important;
  flex-direction: row;
}

.titleCardSection {
  color: #F26716;
  font-weight: 600;
  font-size: 30px;
}

.whatsAppIcon {
  width: 50px;
  height: 50px;
}


/* Home page */

.headText {
  font-weight: 700;
  text-align: center;
  font-size: 40px;
}


.container-vital-info {
  width: auto;
  height: 100px;
  border: 1px solid rgb(255, 174, 0) !important;
  background-color: rgb(255, 217, 0);
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container-vital-info>h1 {
  font-weight: 700 !important;
}